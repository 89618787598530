import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rem, fontSize, fontWeights, colors } from "../styles/utils";
import { desktop } from "../styles/breakpoints";
import {
  Container,
  sectionTxt,
  centerTxt,
  H2,
  H3,
  bodyTxt,
} from "../styles/shared";

import Image from "./image";
import VideoBG from "./video-background";

const { mint } = colors;

const rowStyle = ({ odd }) => css`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  @media (${desktop}) {
    align-items: center;
    flex-direction: ${odd ? "row-reverse" : "row"};
    text-align: initial;
  }
`;

const colStyle = css`
  margin: auto;

  @media (${desktop}) {
    display: flex;
    width: 50%;
  }
`;

const flexRow = ({ odd }) => css`
  @media (${desktop}) {
    justify-content: flex-end;
    flex-direction: ${odd ? "row-reverse" : "row"};
    padding: 0 ${rem(60)};
    padding-left: ${!odd ? 0 : rem(120)};
    padding-right: ${odd ? 0 : rem(120)};
  }
`;

const flexCol = ({ odd }) => css`
  @media (${desktop}) {
    flex-direction: column;
    text-align: ${odd ? "right" : "left"};
    align-items: ${odd ? "flex-end" : "flex-start"};
  }
`;

export const StepList = styled.div`
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: ${rem(120)};
`;

const halfBlue = css`
  &::before {
    content: "";
    position: absolute;
    top: 0;
    height: calc(50% - ${rem(60)});
    left: 0;
    right: 0;
    background: ${mint};
    z-index: -1;
  }

  @media (${desktop}) {
    &::before {
      height: calc(50% + ${rem(22)});
    }
  }
`;

export const StepWrap = styled.div`
  position: relative;
  ${({ idx }) => idx === 0 && halfBlue};
`;

export const StepItem = styled(Container)`
  ${rowStyle};
  margin-bottom: ${rem(40)};

  @media (${desktop}) {
    padding-top: ${rem(45)};
    padding-bottom: ${rem(16)};
  }
`;

export const StepFlatList = styled(StepList)`
  padding: 0;
  margin: 0 auto;

  @media (${desktop}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: ${rem(40)};
  }
`;

export const StepFlatLI = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${rem(30)};

  @media (${desktop}) {
    width: calc(100% / 3 - ${rem(60)});
    max-width: ${rem(300)};
    padding: 0 ${rem(30)};
    max-width: ${rem(360)};
    margin: 0;
  }
`;

const imgSize = css`
  max-width: 100%;
  width: ${rem(180)};
`;

export const StepImg = styled(Image)`
  border-radius: 100%;
  ${imgSize};
  overflow: hidden;
`;

export const StepVideo = styled(VideoBG)`
  ${imgSize};
  height: 0;
  padding-top: 100%;
  border-radius: 100%;
  overflow: hidden;

  video {
    height: 100%;
    width: auto;
  }
`;

export const StepCount = styled.div`
  ${colStyle};
  ${flexRow};
`;

export const StepContent = styled.div`
  ${colStyle};
  ${flexCol};
`;

const positionTxt = ({ reverse }) => css`
  position: absolute;
  top: ${rem(-30)};
  ${reverse ? "right" : "left"}: ${rem(-30)};
`;

export const StepCountTxt = styled.span`
  ${positionTxt};
  display: block;
  ${fontSize(60, 60)};
  font-weight: ${fontWeights.black};
  text-align: left;
`;

export const StepIcon = styled.div`
  position: relative;
  max-width: ${rem(180)};
  margin-top: ${rem(20)};

  @media (${desktop}) {
    max-width: 100%;
  }
`;

export const StepTitle = styled(H3)`
  max-width: ${rem(500)};
  margin-top: ${rem(30)};
  margin-bottom: ${rem(10)};
  text-transform: uppercase;
`;

export const StepText = styled.p`
  ${bodyTxt};
  margin: 0;
  max-width: ${rem(500)};
`;

export const StepHeroBox = styled.section`
  padding-top: ${rem(100)};
  padding-bottom: ${rem(100)};
  background-color: ${mint};
`;

export const StepHeroContent = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const StepHeroTitle = styled.h2`
  ${sectionTxt};
  ${centerTxt};
  text-transform: uppercase;
  margin: 0 auto ${rem(20)};
`;

export const StepHeroText = styled(H2)`
  ${centerTxt};
  ${fontSize(18, 28)};
  margin: 0 auto ${rem(30)};

  @media (${desktop}) {
    margin-bottom: ${rem(60)};
  }
`;

export const stepHeroBtn = css`
  margin-top: 0;
`;
